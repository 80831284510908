import {  base_url,end_points} from "../config";
const axiosInstance = window.axiosInstance;

const updateCallHistoryWithRetry = async (dataToBeUpdated, retries = 2) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            console.log(`Attempt ${attempt}: updating call history...`, dataToBeUpdated);
            await axiosInstance.post(base_url + end_points.updateCallHistory, { dataToBeUpdated });
            console.log("Call history updated successfully.");
            return true; 
        } catch (error) {
            console.log(`Attempt ${attempt} failed: `, error);
            if (attempt === retries) {
                console.error("All retry attempts failed.");
                return false; 
            }
        }
    }
};

let logReportData =  (socket,initiationId,data)=>  {
    const body = { callId: data.callId, initiationId, formData: data, eventFrom: 'video-kyc'};

    updateCallHistoryWithRetry(body).then((response)=>{
        if(response) {
            if(body.formData.onPage && body.formData.onPage == "Landing" && response == true) {
                eventBus.$emit("landing-page-mis-triggered");
            }
            //This is a custom event that is triggered after Prerequisite check is passed and reported to MIS
            if(body.formData.currentProcess && body.formData.currentProcess ==  "geo location fetched passed" && response == true){
                eventBus.$emit("completed-prerequisites-check");
            }
        }
    }).catch((error) => {
        console.error(`Error in logging event: ${body.formData.currentProcess}, error: ${error}`);
    })
}

export { logReportData }